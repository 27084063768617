.list-image {
    list-style-image:url('/public/icons/list-icon.png'); 
    
}
.list-image-check{
    list-style-image:url('/public/icons/list-icon-check.png'); 
}
.list-image-check-green{
    list-style-image:url('/public/icons/list-icon-check-2.png'); 

}

.list-image-check-green li::marker {
    font-size: 1.0rem; /* You can use px, but I think rem is more respecful */
  }

.img-zoom div {
    height: 100% !important;
}

a:hover{
    opacity: .8;
}