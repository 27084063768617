@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "gilory";
    src: url("../public/fonts/Gilroy/Gilroy-Light.otf");
    font-weight: lighter;
  }
@font-face {
    font-family: "gilory";
    src: url("../public/fonts/Gilroy/Gilroy-ExtraBold.otf");
    font-weight: bold;
}

body{
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'gilory';
}